export interface StateData {
  name: string;
  id: string;
  viewBox: string;
  code: string;
}

export const states: StateData[] = [
  {
    name: "Alabama",
    id: "alabama",
    code: "AL",
    viewBox:
      "247.09788513183594 113.81999969482422 32.88111877441406 43.916404724121094",
  },
  {
    name: "Alaska",
    id: "alaska",
    code: "AK",
    viewBox:
      "67.85543060302734 11.09469985961914 46.050567626953125 36.59400177001953",
  },
  {
    name: "Arcansas",
    id: "arcansas",
    code: "AZ",
    viewBox:
      "210.00799560546875 107.63500213623047 37.94134521484375 35.57299041748047",
  },
  {
    name: "Arizona",
    id: "arizona",
    code: "AR",
    viewBox:
      "93.08204650878906 100.83000183105469 47.17295837402344 51.8800048828125",
  },
  {
    name: "California",
    id: "california",
    code: "CA",
    viewBox:
      "54.33403396606445 55.07709884643555 54.90896224975586 83.60435485839844",
  },
  {
    name: "Canzas",
    id: "canzas",
    code: "CO",
    viewBox:
      "169.00799560546875 86.5634994506836 50.77000427246094 31.029273986816406",
  },
  {
    name: "Colorado",
    id: "colorado",
    code: "CT",
    viewBox:
      "130.2480010986328 77.70020294189453 49.27067565917969 39.749794006347656",
  },
  {
    name: "Connecticut",
    id: "connecticut",
    code: "DE",
    viewBox:
      "312.76800537109375 48.29496383666992 19.647979736328125 19.80563735961914",
  },
  {
    name: "Delaware",
    id: "delaware",
    code: "DC",
    viewBox:
      "306.3550109863281 68.65035247802734 16.433990478515625 19.41706085205078",
  },
  {
    name: "District of Columbia",
    id: "columbia_2",
    code: "FL",
    viewBox:
      "67.85543060302734 11.09469985961914 46.050567626953125 36.59400177001953",
  },
  {
    name: "Florida",
    id: "florida",
    code: "GA",
    viewBox:
      "254.45162963867188 136.95993041992188 62.668701171875 45.37933349609375",
  },
  {
    name: "Georgia",
    id: "georgia",
    code: "HI",
    viewBox:
      "261.52099609375 111.03900146484375 41.412628173828125 40.23899841308594",
  },
  {
    name: "Hawaii",
    id: "hawaii",
    code: "ID",
    viewBox:
      "67.85543060302734 11.09469985961914 46.050567626953125 36.59400177001953",
  },
  {
    name: "Idaho",
    id: "idaho",
    code: "IL",
    viewBox:
      "93.904296875 17.46190071105957 42.31370544433594 62.17500305175781",
  },
  {
    name: "Illinois",
    id: "illinois",
    code: "IN",
    viewBox:
      "224.55836486816406 65.52249908447266 32.391265869140625 48.21849822998047",
  },
  {
    name: "Indiana",
    id: "indiana",
    code: "IA",
    viewBox: "243.78500366210938 67.7666015625 27.4892578125 39.58873748779297",
  },
  {
    name: "Iowa",
    id: "iowa",
    code: "KS",
    viewBox:
      "196.8885955810547 60.859397888183594 43.37359619140625 31.634002685546875",
  },
  {
    name: "Kentucky",
    id: "kentucky",
    code: "KY",
    viewBox:
      "238.83200073242188 85.19139862060547 49.230987548828125 32.03160095214844",
  },
  {
    name: "Louisiana",
    id: "louisiana",
    code: "LA",
    viewBox:
      "214.89100646972656 131.7519989013672 42.90400695800781 35.70074462890625",
  },
  {
    name: "Maine",
    id: "maine",
    code: "ME",
    viewBox:
      "316.4179992675781 8.108400344848633 29.686248779296875 41.67500114440918",
  },
  {
    name: "Massachusetts",
    id: "massachusetts",
    code: "MD",
    viewBox:
      "312.3280029296875 41.21189880371094 28.49407958984375 19.843002319335938",
  },
  {
    name: "Meryland",
    id: "meryland",
    code: "MA",
    viewBox:
      "287.5060119628906 69.9198989868164 35.145294189453125 21.709999084472656",
  },
  {
    name: "Michigan",
    id: "michigan",
    code: "MI",
    viewBox:
      "225.12100219726562 33.935585021972656 41.165069580078125 23.70331573486328",
  },
  {
    name: "Minesota",
    id: "minesota",
    code: "MN",
    viewBox:
      "192.54835510253906 22.303699493408203 45.21064758300781 50.54600143432617",
  },
  {
    name: "Mississippi",
    id: "mississippi",
    code: "MS",
    viewBox:
      "230.1209716796875 116.18800354003906 30.244033813476562 43.11517333984375",
  },
  {
    name: "Missouri",
    id: "missouri",
    code: "MO",
    viewBox:
      "202.14500427246094 80.6308364868164 48.02716064453125 40.62816619873047",
  },
  {
    name: "Montana",
    id: "montana",
    code: "MT",
    viewBox:
      "106.87899780273438 18.374000549316406 65.16400146484375 44.303001403808594",
  },
  {
    name: "Nebraska",
    id: "nebraska",
    code: "NE",
    viewBox:
      "159.23800659179688 66.1406021118164 55.589996337890625 31.03009796142578",
  },
  {
    name: "Nevada",
    id: "nevada",
    code: "NV",
    viewBox:
      "74.029296875 60.872100830078125 44.79669952392578 62.817901611328125",
  },
  {
    name: "New Hampshire",
    id: "new-hampshire",
    code: "NH",
    viewBox:
      "314.4803466796875 26.085899353027344 19.186798095703125 28.832000732421875",
  },
  {
    name: "New Jersey",
    id: "new-jersey",
    code: "NJ",
    viewBox:
      "307.0075988769531 57.575199127197266 17.734161376953125 26.19729232788086",
  },
  {
    name: "New Mexico",
    id: "new-mexico",
    code: "NM",
    viewBox:
      "125.86700439453125 104.78700256347656 47.62300109863281 48.50469970703125",
  },
  {
    name: "New York",
    id: "new-york",
    code: "NY",
    viewBox:
      "313.1563415527344 54.58007049560547 19.63189697265625 16.79906463623047",
  },
  {
    name: "North Carolina",
    id: "north-carolina",
    code: "NC",
    viewBox:
      "268.6681823730469 90.90039825439453 56.200347900390625 31.65160369873047",
  },
  {
    name: "North Dakota",
    id: "north-dakota",
    code: "ND",
    viewBox:
      "160.9199981689453 25.11520004272461 45.572998046875 31.45191192626953",
  },
  {
    name: "Ohio",
    id: "ohio",
    code: "OH",
    viewBox:
      "257.53900146484375 61.60060119628906 33.29052734375 36.26300048828125",
  },
  {
    name: "Oklahoma",
    id: "oklahoma",
    code: "OK",
    viewBox:
      "163.35899353027344 106.58899688720703 58.712005615234375 32.915443420410156",
  },
  {
    name: "Oregon",
    id: "oregon",
    code: "OR",
    viewBox:
      "58.019500732421875 27.831035614013672 53.72149658203125 46.51626205444336",
  },
  {
    name: "Pennsylvania",
    id: "pennsylvania",
    code: "PA",
    viewBox:
      "278.4490051269531 54.32324981689453 42.342529296875 32.30963897705078",
  },
  {
    name: "Rod Island",
    id: "rod-island",
    code: "RI",
    viewBox:
      "320.9570007324219 47.67094421386719 13.11712646484375 15.289955139160156",
  },
  {
    name: "South Carolina",
    id: "south-carolina",
    code: "SC",
    viewBox:
      "274.581787109375 107.47676849365234 38.1531982421875 30.273231506347656",
  },
  {
    name: "South Dakota",
    id: "south-dakota",
    code: "SD",
    viewBox:
      "159.83399963378906 46.09960174560547 48.08500671386719 33.50627136230469",
  },
  {
    name: "Tennessee",
    id: "tennessee",
    code: "TN",
    viewBox:
      "235.10694885253906 98.7020034790039 56.08503723144531 29.097000122070312",
  },
  {
    name: "Texas",
    id: "texas",
    code: "TX",
    viewBox:
      "140.46499633789062 110.67092895507812 88.61656188964844 81.21925354003906",
  },
  {
    name: "Utah",
    id: "utah",
    code: "UT",
    viewBox:
      "102.91000366210938 67.2676010131836 40.30999755859375 47.518402099609375",
  },
  {
    name: "Vermont",
    id: "vermont",
    code: "VT",
    viewBox:
      "306.8139953613281 28.68360137939453 19.350006103515625 27.462997436523438",
  },
  {
    name: "Virginia",
    id: "virginia",
    code: "VA",
    viewBox:
      "270.2380065917969 75.36519622802734 52.2559814453125 35.72380065917969",
  },
  {
    name: "Washington",
    id: "washington",
    code: "WA",
    viewBox:
      "67.85543060302734 11.09469985961914 46.050567626953125 36.59400177001953",
  },
  {
    name: "West Virginia",
    id: "west-virginia",
    code: "WV",
    viewBox:
      "273.43707275390625 70.5927963256836 33.72991943359375 34.138084411621094",
  },
  {
    name: "Wisconsin",
    id: "wisconsin",
    code: "WI",
    viewBox:
      "214.05499267578125 37.05961227416992 37.49900817871094 40.26559066772461",
  },
  {
    name: "Wyoming",
    id: "wyoming",
    code: "WY",
    viewBox:
      "122.92432403564453 49.2871208190918 47.756675720214844 40.53517532348633",
  },
];

export const simplyStates = [
  { name: "Alabama", abbreviation: "AL" },
  { name: "Alaska", abbreviation: "AK" },
  { name: "Arizona", abbreviation: "AZ" },
  { name: "Arkansas", abbreviation: "AR" },
  { name: "California", abbreviation: "CA" },
  { name: "Colorado", abbreviation: "CO" },
  { name: "Connecticut", abbreviation: "CT" },
  { name: "Delaware", abbreviation: "DE" },
  { name: "District of Columbia", abbreviation: "DC" },
  { name: "Florida", abbreviation: "FL" },
  { name: "Georgia", abbreviation: "GA" },
  { name: "Hawaii", abbreviation: "HI" },
  { name: "Idaho", abbreviation: "ID" },
  { name: "Illinois", abbreviation: "IL" },
  { name: "Indiana", abbreviation: "IN" },
  { name: "Iowa", abbreviation: "IA" },
  { name: "Kansas", abbreviation: "KS" },
  { name: "Kentucky", abbreviation: "KY" },
  { name: "Louisiana", abbreviation: "LA" },
  { name: "Maine", abbreviation: "ME" },
  { name: "Maryland", abbreviation: "MD" },
  { name: "Massachusetts", abbreviation: "MA" },
  { name: "Michigan", abbreviation: "MI" },
  { name: "Minnesota", abbreviation: "MN" },
  { name: "Mississippi", abbreviation: "MS" },
  { name: "Missouri", abbreviation: "MO" },
  { name: "Montana", abbreviation: "MT" },
  { name: "Nebraska", abbreviation: "NE" },
  { name: "Nevada", abbreviation: "NV" },
  { name: "New Jersey", abbreviation: "NJ" },
  { name: "New Hampshire", abbreviation: "NH" },
  { name: "New Mexico", abbreviation: "NM" },
  { name: "New York", abbreviation: "NY" },
  { name: "North Carolina", abbreviation: "NC" },
  { name: "North Dakota", abbreviation: "ND" },
  { name: "Ohio", abbreviation: "OH" },
  { name: "Oklahoma", abbreviation: "OK" },
  { name: "Oregon", abbreviation: "OR" },
  { name: "Pennsylvania", abbreviation: "PA" },
  { name: "Rhode Island", abbreviation: "RI" },
  { name: "South Carolina", abbreviation: "SC" },
  { name: "South Dakota", abbreviation: "SD" },
  { name: "Tennessee", abbreviation: "TN" },
  { name: "Texas", abbreviation: "TX" },
  { name: "Utah", abbreviation: "UT" },
  { name: "Vermont", abbreviation: "VT" },
  { name: "Virginia", abbreviation: "VA" },
  { name: "Washington", abbreviation: "WA" },
  { name: "West Virginia", abbreviation: "WV" },
  { name: "Wisconsin", abbreviation: "WI" },
  { name: "Wyoming", abbreviation: "WY" }
]
