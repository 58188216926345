export function openChat() {
  if (!window.tidioChatApi) return false

  window.tidioChatApi.display(true)
  window.tidioChatApi.open()
}

export function showChat() {
  if (!window.tidioChatApi) return false

  window.tidioChatApi.display(true)
}
