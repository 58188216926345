import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-047d2676"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "address-create" }
const _hoisted_2 = { class: "address-create__content" }
const _hoisted_3 = { class: "custom-address" }
const _hoisted_4 = {
  key: 0,
  class: "custom-address__wrap"
}
const _hoisted_5 = { class: "custom-address__list" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["disabled", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_CustomCheckbox = _resolveComponent("CustomCheckbox")!
  const _component_Form = _resolveComponent("Form")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createBlock(_component_Form, {
    as: "div",
    ref: "addressForm"
  }, {
    default: _withCtx(({ validate, handleReset }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_Input, {
              id: "address1",
              name: "Address",
              value: "",
              label: "Enter your address",
              rules: { required: true },
              modelValue: _ctx.address.address1,
              "onUpdate:modelValue": _ctx.onUpdateModelValue,
              onOnFocus: _ctx.onOpenSuggestions
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onOnFocus"]),
            (_ctx.results.length && _ctx.isOpen && _ctx.inputFocused)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("ul", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (address, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: index,
                        class: _normalizeClass(["custom-address__item", {
                  'is-selected': address?.placeId === _ctx.selectedResult?.placeId,
                }])
                      }, [
                        _createElementVNode("button", {
                          class: "custom-address__button",
                          type: "button",
                          onClick: _withModifiers(($event: any) => (_ctx.onAddressSelected(address)), ["prevent"])
                        }, _toDisplayString(address.text), 9, _hoisted_6)
                      ], 2))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ])), [
            [_directive_click_outside, _ctx.onCloseSuggestions]
          ]),
          _createVNode(_component_Input, {
            id: "address2",
            name: "Apt or Suite #",
            value: "",
            label: "Apt or Suite #",
            modelValue: _ctx.address.address2,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.address.address2) = $event)),
            rules: null,
            onOnChange: _ctx.onInput
          }, null, 8, ["modelValue", "onOnChange"]),
          _createVNode(_component_Input, {
            id: "city",
            value: "",
            name: "City",
            label: "City",
            className: "address-create__item",
            rules: { required: true },
            modelValue: _ctx.address.city,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.address.city) = $event)),
            onOnChange: _ctx.onInput
          }, null, 8, ["modelValue", "onOnChange"]),
          _createVNode(_component_Input, {
            id: "state",
            name: "State",
            label: "State",
            value: "",
            rules: { required: true },
            modelValue: _ctx.address.state,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.address.state) = $event)),
            onOnChange: _ctx.onInput
          }, null, 8, ["modelValue", "onOnChange"]),
          _createVNode(_component_Input, {
            id: "zip",
            name: "Zip",
            label: "Zip",
            value: "",
            modelValue: _ctx.address.zip,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.address.zip) = $event)),
            rules: { required: true },
            onOnChange: _ctx.onInput
          }, null, 8, ["modelValue", "onOnChange"]),
          (_ctx.panelist)
            ? (_openBlock(), _createBlock(_component_CustomCheckbox, {
                key: 0,
                id: "setDefaultAddress",
                checked: _ctx.setDefaultAddress,
                value: _ctx.setDefaultAddress,
                class: "custom-checkbox_md custom-checkbox_reverse address-create__checkbox",
                label: _ctx.$t(`setDefaultAddressText`),
                onOnChange: _ctx.onCheckboxChange
              }, null, 8, ["checked", "value", "label", "onOnChange"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("p", null, [
          _createElementVNode("button", {
            type: "button",
            disabled: !_ctx.hasChanges,
            class: "primary-rewards-btn address-info__button",
            onClick: _withModifiers(($event: any) => (_ctx.onCreate(validate, handleReset)), ["prevent"])
          }, _toDisplayString(_ctx.$t("addressPage.submitCreate")), 9, _hoisted_7)
        ])
      ])
    ]),
    _: 1
  }, 512))
}