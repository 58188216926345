
import { Options, Vue } from "vue-class-component"
import Input from "@/components/Input/index.vue"
import { Form } from "vee-validate"
import { Address } from "@/models/Address"
import { Watch } from "vue-property-decorator"
import CustomCheckbox from "@/components/Checkbox/CustomCheckbox.vue"

import { SearchByPlace } from "@/models/SearchByPlace"
import { getAddressAutocomplete, getAddressMarker } from "@/api/services/AwsService"
import { SearchForSuggestionsResult } from "@aws-amplify/geo/lib-esm/types/Geo"
import { Coordinates } from "maplibre-gl-js-amplify/lib/esm/types"

@Options({
  components: {
    Input,
    Form,
    CustomCheckbox,
  },
  props: {
    errors: {
      type: Object,
    },
    panelist: {
      type: Boolean,
    },
    biasPosition: {
      type: Array,
    },
  },
})
export default class AddressCreate extends Vue {
  address = Address.createDefault()
  hasChanges = false
  errors: any = {}
  panelist = false
  setDefaultAddress = false

  isOpen = false
  inputFocused = false
  map: any | null = null
  results: SearchForSuggestionsResult[] = []
  selectedResult: SearchForSuggestionsResult | null = null
  place?: SearchByPlace | null = null
  biasPosition: Coordinates | null = null

  @Watch("errors")
  watchErrors(): void {
    if (this.errors) {
      ;(this.$refs.addressForm as any).setErrors(this.errors)
    }
  }

  onUpdateModelValue(value: string) {
    this.address.address1 = value
    this.onSearch(value)
  }

  onCloseSuggestions() {
    this.isOpen = false
    this.inputFocused = false
  }

  onOpenSuggestions() {
    this.isOpen = true
    this.inputFocused = true
  }

  async onSearch(value: string) {
    this.results = await getAddressAutocomplete({
      term: value,
      biasPosition: this.biasPosition as Coordinates,
    })
    this.onOpenSuggestions()
  }

  async onAddressSelected(value: SearchForSuggestionsResult) {
    this.onInput()
    if (value === null) {
      this.selectedResult = null
      return false
    }

    this.selectedResult = value

    this.onCloseSuggestions()
    if (value.placeId) {
      this.place = await this.getMarker(value.placeId)
      this.onPlaceSelectedAutocomplete()
    }
  }

  async getMarker(id: string) {
    const result = await getAddressMarker(id)
    return result
  }

  onPlaceSelectedAutocomplete() {
    if (this.place === null || !this.place) return
    const { address1, city, zip, state } = this.place.createAddress(this.address.address2)
    this.address.address1 = address1.length > 1 ? address1 : this.address.address1
    this.address.city = city
    this.address.zip = zip
    this.address.state = state
  }

  onInput(): void {
    this.hasChanges = true
  }

  onCheckboxChange({ id, checked }: { id: string; checked: boolean }) {
    if (id === "setDefaultAddress") {
      this.setDefaultAddress = checked
    }
  }

  async onCreate(validate: any, reset: any) {
    const { valid } = await validate()

    if (valid) {
      this.$emit("create", {
        address: this.address,
        setDefaultAddress: this.panelist ? this.setDefaultAddress : null,
      })

      // reset()
    }
  }
}
