export class Address {
  source?: string
  type?: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  zipDeliveryArea?: string
  isPrimary?: boolean

  constructor(props?: any) {
    this.set(props)
  }

  set(props?: any): void {
    this.setDefaultData()

    if (!props) return

    this.isPrimary = props.isPrimary ?? false
    this.address1 = props.address1 ?? ""
    this.address2 = props.address2 ?? ""
    this.city = props.city ?? ""
    this.state = props.state ?? ""
    this.zip = props.zip ?? ""
  }

  setDefaultData(): void {
    this.isPrimary = false
    this.address1 = ""
    this.address2 = ""
    this.city = ""
    this.state = ""
    this.zip = ""
    this.zipDeliveryArea = ""
    this.source = ""
    this.type = ""
  }

  static create(props: any): Address {
    return new Address(props)
  }

  static createDefault(): Address {
    return new Address()
  }

  get toString(): string {
    return `${this.address1.toLowerCase()} 
      ${this.address2.toLowerCase()}
      ${this.city ? this.city.toLowerCase() + "," : ""} ${this.state} ${
      this.zip
    } 
    `
  }

  get hasAddress() {
    return this.toString.trim().length > 0
  }

  getJsonObj() {
    return {
      address1: this.address1,
      address2: this.address2,
      city: this.city,
      state: this.state,
      zip: this.zip
    }
  }
}
