
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    value: {
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
    },
  },
})
export default class CustomCheckbox extends Vue {
  id: string;

  onChange(e: Event): void {
    this.$emit("on-change", {
      id: this.id,
      value: (e.target as HTMLInputElement).value,
      checked: (e.target as HTMLInputElement).checked,
    });
  }
}
