export const timeStampToString = (
  time: string,
  timeFormatOptions: Intl.DateTimeFormatOptions = { hour: "2-digit", minute: "2-digit", hour12: true },
  dateFormatOptions: Intl.DateTimeFormatOptions = { day: "2-digit", month: "2-digit", year: "numeric" },
  displayTime = true
): string => {
  if (!time) return "--";
  const locale = "en";
  const date = new Date(time);

  const timeString = date.toLocaleTimeString(locale, timeFormatOptions);
  const dateString = date.toLocaleDateString(locale, dateFormatOptions);
  return `${dateString}${displayTime ? " " + timeString : ""}`;
};
