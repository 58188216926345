import { Geo } from "aws-amplify"
import Common from "./Common"
import {
  Place,
  SearchForSuggestionsResult
} from "@aws-amplify/geo/lib-esm/types/Geo"

import { SearchByPlace } from "@/models/SearchByPlace"
import { Coordinates } from "maplibre-gl-js-amplify/lib/esm/types"
export default class AwsService extends Common {
  static async searchPois(placeId: string) {
    let result: Place | null = null

    try {
      result = await Geo.searchByPlaceId(placeId, {})

      return SearchByPlace.create(result)
    } catch (error) {
      super.toError(error)
    }
  }

  static async suggestPois({
    term,
    biasPosition
  }: {
    term: string
    biasPosition?: Coordinates
  }) {
    let results: SearchForSuggestionsResult[] = []

    if (!term.length) return results

    try {
      results = await Geo.searchForSuggestions(term, {
        countries: ["USA"], // Alpha-3 country codes
        maxResults: 7,
        biasPosition // [0] - longitude, [1] - latitude
      })

      return results
    } catch (error) {
      super.toError(error)
    }

    return results
  }
}

export const getAddressAutocomplete = AwsService.suggestPois
export const getAddressMarker = AwsService.searchPois
