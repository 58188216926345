
import { Options, Vue } from "vue-class-component"
import AddressCreate from "@/components/Address/AddressCreate.vue"
import AddressSearch from "@/components/Address/AddressSearch.vue"
import Input from "@/components/Input/index.vue"
import { Address } from "@/models/Address"
import {
  setContactAddress,
  setEzContactAddress,
  getPracticeLocationAddress,
  getGeoLocation
} from "@/api/services/ContactService"
import { Invite } from "@/models/Invite"
import { getInvite } from "@/api/services/InviteService"
import { Form } from "vee-validate"
import "maplibre-gl/dist/maplibre-gl.css"
import { SearchByPlace } from "@/models/SearchByPlace"
import {
  getAddressAutocomplete,
  getAddressMarker
} from "@/api/services/AwsService"
import { SearchForSuggestionsResult } from "@aws-amplify/geo/lib-esm/types/Geo"
import CustomCheckbox from "@/components/Checkbox/CustomCheckbox.vue"
import { getCurrentLatLong } from "@/utils"
import { Coordinates } from "maplibre-gl-js-amplify/lib/esm/types"
import { createStaticMapUrl } from "@/utils"

@Options({
  components: {
    AddressCreate,
    AddressSearch,
    Input,
    Form,
    CustomCheckbox
  }
})
export default class AddressEdit extends Vue {
  apart = ""

  hasChanges = false
  selectedAddress: Address = Address.createDefault()
  createAddress = false
  showMap = false
  invite: Invite | null = null

  results: SearchForSuggestionsResult[] = []
  selectedResult: SearchForSuggestionsResult | null = null
  place?: SearchByPlace | null = null

  setDefaultAddress = false

  createAddressErrors: { [k: string]: string[] } | null = null
  position: any | null = null
  staticMapUrl = ""

  get id(): string {
    return this.$route.query.id as string
  }

  get type(): string {
    return this.$route.query.is_legacy as string
  }

  get typeIsEz(): boolean {
    return this.type === "true"
  }

  async created() {
    if (!this.typeIsEz) {
      this.getInvite()
    }

    this.position = await getCurrentLatLong({
      type: "biasPosition"
    })

    if (this.position === null && !this.typeIsEz) {
      const practiceLocationAddress = await getPracticeLocationAddress(this.id)

      if (practiceLocationAddress === null) return

      const { zip } = practiceLocationAddress

      if (!zip) return
      this.position = await getGeoLocation(zip)
    }
  }

  async onSearch({ search }: { search: string }) {
    this.results = await getAddressAutocomplete({
      term: search,
      biasPosition: this.position as Coordinates
    })
  }

  addMarker() {
    const [lng, lat] = this.place?.geometry?.point ?? [0, 0]

    this.staticMapUrl = createStaticMapUrl({
      markers: `color:red|${lat},${lng}`
    })
  }

  onChange() {
    this.hasChanges = true
  }

  onCheckboxChange({ id, checked }: { id: string; checked: boolean }) {
    if (id === "setDefaultAddress") {
      this.setDefaultAddress = checked
    }
  }

  async onAddressSelected(payload: {
    value: SearchForSuggestionsResult | null
  }) {
    this.onChange()

    const { value } = payload

    if (value === null) {
      this.selectedResult = null
      this.showMap = false
      return false
    }

    this.selectedResult = value

    if (value.placeId) {
      this.place = await this.getMarker(value.placeId)

      this.addMarker()
    }

    this.showMap = true
  }

  async getMarker(id: string) {
    const result = await getAddressMarker(id)

    return result
  }

  async getInvite() {
    const invite = await getInvite(this.id)

    if (invite) {
      this.invite = invite
    }
  }

  onToggleCreateAddress(): void {
    this.createAddress = !this.createAddress
  }

  onNextPage() {
    this.$router.push({
      name: "allFinished",
      query: {
        id: this.id,
        is_legacy: this.type
      }
    })
  }

  async onSave(validate: any) {
    const { valid } = await validate()

    if (valid) {
      const address = this.place?.createAddress(this.apart)

      if (!address) return

      if (this.typeIsEz) {
        this.onSubmitAddressToEz({ address })
      } else {
        this.onCreateAndSubmit({
          address,
          setDefaultAddress:
            this.invite && this.invite.isPanelist
              ? this.setDefaultAddress
              : true
        })
      }
    }
  }

  async onSubmitAddressToEz({
    address
  }: {
    address: Address
  }): Promise<boolean | void> {
    const httpError = await setEzContactAddress({
      id: this.id,
      address
    })

    if (httpError) {
      const errorMessage = {
        Address: [
          httpError.error?.response?.data?.data?.exception ??
            "The address is invalid"
        ]
      }

      if (this.createAddress) {
        this.createAddressErrors = errorMessage
      } else {
        ;(this.$refs.addressSearch as any).setErrors(errorMessage)
      }
      return false
    }

    this.onNextPage()
  }

  async onCreateAndSubmit({
    address,
    setDefaultAddress
  }: {
    address: Address
    setDefaultAddress: boolean | null
  }): Promise<boolean | void> {
    const httpError = await setContactAddress(
      this.id,
      address,
      setDefaultAddress
    )

    if (httpError) {
      const errorMessage = {
        Address: [
          httpError.error?.response?.data?.data?.exception ??
            "The address is invalid"
        ]
      }

      if (this.createAddress) {
        this.createAddressErrors = errorMessage
      } else {
        ;(this.$refs.addressSearch as any).setErrors(errorMessage)
      }
      return false
    }

    this.onNextPage()
  }
}
