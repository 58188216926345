import { PlaceGeometry, Place } from "@aws-amplify/geo/lib-esm/types/Geo"
import { Address } from "@/models/Address"
import { simplyStates } from "@/utils"

export class SearchByPlace {
  addressNumber?: string
  country?: string
  geometry: PlaceGeometry | undefined
  label?: string
  municipality?: string
  neighborhood?: string
  postalCode?: string
  region?: string
  street?: string
  subRegion?: string

  constructor(props: Place) {
    this.set(props)
  }

  static create(props: Place): SearchByPlace {
    return new SearchByPlace(props)
  }

  set(props: Place) {
    this.setDefaultData()

    if (!props) return

    this.addressNumber = props.addressNumber ?? ""
    this.country = props.country ?? ""
    this.label = props.label ?? ""
    this.municipality = props.municipality ?? ""
    this.neighborhood = props.neighborhood ?? ""
    this.postalCode = props.postalCode ?? ""
    this.region = props.region ?? ""
    this.street = props.street ?? ""
    this.subRegion = props.subRegion ?? ""
    this.geometry = props.geometry
  }

  setDefaultData(): void {
    this.addressNumber = ""
    this.country = ""
    this.label = ""
    this.municipality = ""
    this.neighborhood = ""
    this.postalCode = ""
    this.region = ""
    this.street = ""
    this.subRegion = ""
    this.geometry = undefined
  }

  createAddress(address2: string): Address {
    const state = simplyStates.find(
      (state) => state.name.toLowerCase() === this.region?.toLowerCase()
    )
    return Address.create({
      address1: `${this.addressNumber} ${this.street}`,
      address2: address2,
      city: this.municipality,
      state: state?.abbreviation ?? "",
      zip: this.postalCode?.split(" ")[0] ?? ""
    })
  }
}
