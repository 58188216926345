
import { Options, Vue } from "vue-class-component"
import Multiselect from "vue-multiselect"
import { Field } from "vee-validate"
import InlineSvg from "vue-inline-svg"

@Options({
  components: {
    Multiselect,
    Field,
    InlineSvg
  },
  props: {
    value: {
      type: [Object, String]
    },
    id: {
      type: String
    },
    name: {
      type: String,
      default: ""
    },
    trackBy: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    rules: {
      default: null
    },
    options: {
      type: Array,
      default: []
    }
  }
})
export default class AddressSearch extends Vue {
  id: string
  value = ""
  name: string
  rules: any
  options: string[] = []
  trackBy: string
  label: string

  onSearch(text: string) {
    this.$emit("search", {
      id: this.id,
      search: text
    })
  }

  updateSelected(selectedOption: any): void {
    this.$emit("selected", {
      id: this.id,
      value: selectedOption
    })
  }

  onFooterClick(): void {
    this.$emit("footerClick")
  }

  onClear() {
    this.updateSelected(null)
  }
}
