import axios from "axios"
import { Endpoint } from "@/api/endpoints"

import { Contact } from "@/models/Contact"
import { Address } from "@/models/Address"
import Common from "./Common"

export default class ContactService extends Common {
  static async getById(inviteLinkId: string): Promise<Contact | undefined> {
    try {
      const { data } = await axios.get(Endpoint.GET_CONTACT().url, {
        params: { inviteLinkId }
      })

      return Contact.create(data.data.contact)
    } catch (error) {
      super.toError(error)
    }
  }

  static async changeById(
    inviteLinkId: string,
    payload: Contact
  ): Promise<Contact | undefined> {
    try {
      await axios.put(Endpoint.CHANGE_CONTACT().url, payload.getJsonObj(), {
        params: { inviteLinkId }
      })

      return
    } catch (error) {
      super.toError(error)
    }
  }

  static async getAddress(id: string): Promise<Address | null> {
    try {
      const { data } = await axios.get(Endpoint.CONTACT_ADDRESS().url, {
        params: { inviteLinkId: id }
      })
      return Address.create(data)
    } catch (error) {
      super.toError(error)
      return null
    }
  }

  static async getPracticeLocationAddress(id: string): Promise<Address | null> {
    try {
      const { data } = await axios.get(
        Endpoint.GET_PRACTICE_LOCATION_ADDRESS().url,
        {
          params: { inviteLinkId: id }
        }
      )
      return Address.create(data)
    } catch (error) {
      super.toError(error)
      return null
    }
  }

  static async getGeoLocation(zip: string) {
    try {
      const { data } = await axios.get(Endpoint.GET_GEO_LOCATION().url, {
        params: { zip }
      })

      return data.data.location
    } catch (error) {
      super.toError(error)
      return null
    }
  }

  static async setAddress(
    id: string,
    address: Address,
    setDefaultAddress: boolean | null
  ): Promise<any> {
    try {
      await axios.post(Endpoint.CONTACT_ADDRESS().url, address.getJsonObj(), {
        params: { inviteLinkId: id, "set-payment-address": setDefaultAddress }
      })
    } catch (error) {
      return super.toError(error)
    }
  }

  static async setEzAddress({
    id,
    address
  }: {
    id: string
    address: Address
  }): Promise<any> {
    try {
      await axios.post(
        Endpoint.EZ_CONTACT_ADDRESS({ id }).url,
        address.getJsonObj()
      )
    } catch (error) {
      return super.toError(error)
    }
  }

  static async confirmAddress(id: string): Promise<void> {
    try {
      await axios.post(Endpoint.CONFIRM_ADDRESS().url, null, {
        params: { inviteLinkId: id }
      })
    } catch (error) {
      super.toError(error)
    }
  }
}

export const getContact = ContactService.getById
export const changeContact = ContactService.changeById
export const getContactAddress = ContactService.getAddress
export const getPracticeLocationAddress =
  ContactService.getPracticeLocationAddress
export const setContactAddress = ContactService.setAddress
export const setEzContactAddress = ContactService.setEzAddress
export const confirmContactAddress = ContactService.confirmAddress
export const getGeoLocation = ContactService.getGeoLocation
