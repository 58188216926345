import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "value", "onBlur", "onInput"]
const _hoisted_3 = {
  key: 0,
  class: "custom-input__error"
}
const _hoisted_4 = {
  key: 1,
  class: "custom-input__helper"
}
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createBlock(_component_Field, {
    name: _ctx.name ?? _ctx.id,
    rules: _ctx.rules,
    modelValue: _ctx.content,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.content) = $event))
  }, {
    default: _withCtx(({ field, errors, handleChange }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["custom-input", [
        { 'custom-input_focused': _ctx.focused },
        { 'custom-input_with-data': _ctx.isFocused },
        { 'is-disabled': _ctx.isDisabled },
        { 'is-error': errors[0] },
        { 'is-ok': _ctx.focusedOutline ? _ctx.isValidated || _ctx.hasValue : false },
        _ctx.className
      ]])
      }, [
        _createElementVNode("label", {
          for: _ctx.id,
          class: "custom-input__label"
        }, _toDisplayString(_ctx.label), 9, _hoisted_1),
        _withDirectives(_createElementVNode("input", {
          id: _ctx.id,
          type: "text",
          class: "custom-input__input",
          value: field.value,
          onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
          onBlur: ($event: any) => (_ctx.onBlur(errors)),
          onInput: ($event: any) => (_ctx.onInput(handleChange, $event))
        }, null, 40, _hoisted_2), [
          [_directive_maska, _ctx.mask]
        ]),
        (errors[0])
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(errors[0]), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, [
              _renderSlot(_ctx.$slots, "helper")
            ])),
        (_ctx.icon)
          ? (_openBlock(), _createElementBlock("img", {
              key: 2,
              src: require(`@/assets/images/rewards/${_ctx.icon}.svg`),
              alt: "",
              class: "custom-input__icon",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onIconClick && _ctx.onIconClick(...args)))
            }, null, 8, _hoisted_5))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 3
  }, 8, ["name", "rules", "modelValue"]))
}