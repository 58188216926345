import { Coordinates } from "maplibre-gl-js-amplify/lib/esm/types"
import { GOOGLE_API_KEY } from "@/config"

export const getCurrentLatLong = async ({
  type
}: {
  type?: "biasPosition"
}) => {
  const getCurrentPosition = () =>
    new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject, {})
    )

  try {
    const pos: any = await getCurrentPosition()

    const { coords } = pos

    return type === "biasPosition"
      ? ([coords.longitude, coords.latitude] as Coordinates)
      : {
          longitude: coords.longitude,
          latitude: coords.latitude
        }
  } catch (e: any) {
    console.warn(e.message || "getCurrentPosition error")

    return null
  }
}

export const createStaticMapUrl = (param: { [key: string]: string }) => {
  const baseUrl = new URL("https://maps.googleapis.com/maps/api/staticmap")
  const searchParams = new URLSearchParams(
    Object.assign(
      {
        scale: "2",
        format: "jpg",
        language: "en",
        zoom: "16",
        size: "690x183"
      },
      param,
      { key: GOOGLE_API_KEY }
    )
  )

  return `${baseUrl}?${searchParams.toString()}`
}
