
import { Options, Vue } from "vue-class-component"
import { Field } from "vee-validate"

@Options({
  components: {
    Field
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    modelValue: {
      type: [String, Object],
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    icon: {
      type: String,
      default: ""
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    rules: {
      default: null
    },
    className: {
      default: ""
    },
    mask: {
      type: String,
      default: ""
    },
    focusedOutline: {
      type: Boolean,
      default: true
    }
  }
})
export default class Input extends Vue {
  id: string
  modelValue: any
  focused = false
  isDisabled: boolean
  label: string
  icon: string
  name: string
  rules: any
  className: string
  isValidated = false
  focusedOutline: boolean

  get content() {
    return this.modelValue
  }

  set content(value: any) {
    this.$emit("update:modelValue", value)
    this.$emit("onChange", {
      id: this.id,
      value
    })
  }

  get hasValue() {
    return this.content.length > 0
  }

  get isFocused(): boolean {
    return this.hasValue || this.focused
  }

  onFocus(): void {
    this.focused = true
    this.$emit("onFocus")
  }

  onBlur(errors: any): void {
    this.focused = false
    this.isValidated = !errors[0] && this.hasValue
    this.$emit("onBlur")
  }

  onInput(handleChange: any, event: any): void {
    this.isValidated = false
    handleChange(event)
  }

  onIconClick(): void {
    this.$emit("onIconClick")
  }
}
