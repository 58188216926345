import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-140b2062"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "address"
}
const _hoisted_2 = { class: "h2 address__title" }
const _hoisted_3 = { class: "h4 address__desc" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "address-form" }
const _hoisted_6 = {
  key: 0,
  class: "address-form__split-row"
}
const _hoisted_7 = ["disabled", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddressCreate = _resolveComponent("AddressCreate")!
  const _component_AddressSearch = _resolveComponent("AddressSearch")!
  const _component_Input = _resolveComponent("Input")!
  const _component_CustomCheckbox = _resolveComponent("CustomCheckbox")!
  const _component_Form = _resolveComponent("Form")!

  return (_ctx.invite || _ctx.typeIsEz)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("funPart")), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("addressPage.receive")), 1),
        (_ctx.createAddress)
          ? (_openBlock(), _createBlock(_component_AddressCreate, {
              key: 0,
              errors: _ctx.createAddressErrors,
              panelist: _ctx.invite && _ctx.invite.isPanelist,
              biasPosition: _ctx.position,
              onCreate: _ctx.onCreateAndSubmit
            }, null, 8, ["errors", "panelist", "biasPosition", "onCreate"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_Transition, { name: "slidedown" }, {
                default: _withCtx(() => [
                  (_ctx.showMap)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        style: _normalizeStyle({ backgroundImage: `url('${_ctx.staticMapUrl}')` }),
                        class: "address__map",
                        alt: "map"
                      }, null, 4))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_Form, {
                as: "div",
                ref: "addressSearch"
              }, {
                default: _withCtx(({ validate }) => [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_AddressSearch, {
                      id: "address",
                      name: "Address",
                      trackBy: "placeId",
                      label: "text",
                      rules: { required: true },
                      options: _ctx.results,
                      value: _ctx.selectedResult,
                      onSearch: _ctx.onSearch,
                      onSelected: _ctx.onAddressSelected,
                      onFooterClick: _ctx.onToggleCreateAddress
                    }, null, 8, ["options", "value", "onSearch", "onSelected", "onFooterClick"]),
                    _createVNode(_component_Input, {
                      id: "apart",
                      name: "Apt or Suite #",
                      label: "Apt or Suite #",
                      modelValue: _ctx.apart,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.apart) = $event)),
                      rules: null,
                      onOnChange: _ctx.onChange,
                      focusedOutline: false
                    }, null, 8, ["modelValue", "onOnChange"]),
                    (_ctx.invite && _ctx.invite.isPanelist)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_CustomCheckbox, {
                            id: "setDefaultAddress",
                            checked: _ctx.setDefaultAddress,
                            value: _ctx.setDefaultAddress,
                            label: _ctx.$t(`setDefaultAddressText`),
                            class: "custom-checkbox_md custom-checkbox_reverse",
                            onOnChange: _ctx.onCheckboxChange
                          }, null, 8, ["checked", "value", "label", "onOnChange"])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("button", {
                      type: "button",
                      disabled: !_ctx.hasChanges,
                      class: "primary-rewards-btn address-form__button",
                      onClick: _withModifiers(($event: any) => (_ctx.onSave(validate)), ["prevent"])
                    }, _toDisplayString(_ctx.$t("addressPage.submitCreate")), 9, _hoisted_7)
                  ])
                ]),
                _: 1
              }, 512)
            ]))
      ]))
    : _createCommentVNode("", true)
}